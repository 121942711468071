import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Comments from "../components/comments";

const BlogPost = ({ data }) => {
    const post = data.markdownRemark;
    return (
        <Layout pageTitle="Blog Post">
            <div>
                <h1>{post.frontmatter.title}</h1>
                <h4>{post.frontmatter.date}</h4>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <Comments data={post.frontmatter.comments} />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                comments {
                  name
                  date(formatString: "DD MMMM, YYYY")
                  comment
                }
            }
        }
    }
`;

export default BlogPost;
