import * as React from "react";
import Comment from "./comment";

const Comments = (comments) => {
    if (!comments.data) {
        return (
            <div>
                <h4>No Comments</h4>
            </div>
        );
    }

    console.log("got data", comments.data);

    return (
        <div>
            <h3>Comments:</h3>
            {comments.data.map(({ name, date, comment }, index) => {
                return (
                    <Comment data={{ name, date, comment, index }} />
                )
            })}
        </div>
    );
};

export default Comments;
