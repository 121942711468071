import * as React from "react";

const Comment = ({ data }) => {
    return (
        <div key={data.index}>
            <hr />
            <h3>{data.name}</h3>
            <h4>{data.date}</h4>
            <p>{data.comment}</p>
        </div>
    )
};

export default Comment;
